import { ServicesProcessMock } from '~/types/mock'

const MarketplaceAppDevProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Product discovery',
    descr:
      'We explore the subject area and develop prototypes. Our experts create a roadmap for the project and provide accurate cost and time estimates.',
  },
  {
    num: '02',
    title: 'UI/UX design',
    descr:
      'Our talented UI/UX designers craft visually appealing and user-friendly marketplace apps with enhanced user experience.',
  },
  {
    num: '03',
    title: 'App development',
    descr:
      'We build the minimum viable product using proven technologies like Ruby on Rails, React, VueJS, and more.',
  },
  {
    num: '04',
    title: 'Optimization and QA',
    descr:
      'Our dedicated QA engineers prioritize optimization and conduct rigorous testing to ensure the final marketplace app functions flawlessly.',
  },
  {
    num: '05',
    title: 'Deployment and support',
    descr:
      'We provide ongoing maintenance and support services to solve any issues that may arise throughout the product lifecycle.',
  },
]

export default MarketplaceAppDevProcess
