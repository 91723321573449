import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { QueryResult, StrapiService } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import MarketplacesAppDevelopment from '~/views/Services/MarketplacesAppDevelopment/MarketplacesAppDevelopment.view'

const MarketplacesAppDevelopmentPage = () => <MarketplacesAppDevelopment />

export default MarketplacesAppDevelopmentPage

export const query = graphql`
  query {
    strapiService(page_name: { eq: "Marketplace App Development" }) {
      ...MetaTagInformationServices
    }
  }
`

export const Head = ({ data }: QueryResult<StrapiService>) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiService,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image.localFile)) as string}
      url="https://www.codica.com/services/online-marketplace-development/marketplace-app-development/"
    />
  )
}
