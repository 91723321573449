import { ExpertiseSectionMock } from '~/types/mock'

const MarketplaceAppDevBestPractices: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_44',
    title: 'Reasonable cost',
    description:
      'We assist clients in minimizing expenses by setting aside unnecessary features and creating a concise vision of the product.',
  },
  {
    icon: 'spriteIcon_66',
    title: 'Agile methodology',
    description:
      'We follow an efficient and transparent development process, ensuring the timely delivery of your software product within the allocated budget.',
  },
  {
    icon: 'spriteIcon_88',
    title: 'Expert development team',
    description:
      'Our team consists of highly skilled professionals who specialize in creating expert-level apps and can handle all aspects of online marketplace app development.',
  },
  {
    icon: 'spriteIcon_87',
    title: 'Reliable tech stack',
    description:
      'With a wealth of experience in constructing bespoke applications, our skilled team provides the optimal solution for your business needs.',
  },
  {
    icon: 'spriteIcon_27',
    title: 'Support after launch',
    description:
      'Our commitment to your project extends beyond its launch. We will continue to monitor and maintain your app to ensure seamless operation.',
  },
]

export default MarketplaceAppDevBestPractices
