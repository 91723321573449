import { ExpertiseSectionMock } from '~/types/mock'

const MarketplaceAppDevBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_26',
    title: 'Business-to-business (B2B)',
    description:
      'We assist our clients in the development of B2B marketplaces, enabling businesses to engage in buying and selling goods and services with one another.',
  },
  {
    icon: 'spriteIcon_85',
    title: 'Business-to-consumer (B2C)',
    description:
      'To ensure an exceptional shopping experience for your customers, we provide comprehensive B2C marketplace app development services.',
  },
  {
    icon: 'spriteIcon_49',
    title: 'Consumer-to-consumer (C2C)',
    description:
      'We will deliver a high-quality C2C marketplace app that facilitates seamless transactions and interactions between individual users.',
  },
  {
    icon: 'spriteIcon_19',
    title: 'Peer-to-peer (P2P)',
    description:
      'Our P2P app development services focus on creating user-friendly and fast-loading apps where individuals can directly transact with one another.',
  },
  {
    icon: 'spriteIcon_83',
    title: 'Multi-vendor marketplace',
    link: '/services/multi-vendor-marketplace-development/',
    description:
      'We specialize in developing profitable multi-vendor marketplace apps where many vendors can showcase and sell their products or services.',
  },
  {
    icon: 'spriteIcon_29',
    title: 'Ecommerce marketplace',
    link: '/services/ecommerce-marketplace-development/',
    description:
      'Our e-commerce app development services are tailored to create robust platforms similar to Amazon or eBay.',
  },
  {
    icon: 'spriteIcon_99',
    title: 'Market network',
    description:
      'We provide quality market network app development services, which combine elements of social networking and marketplace functionality.',
  },
]

export default MarketplaceAppDevBenefits
