import { StaticImage, getImage } from 'gatsby-plugin-image'

import { CommonImageProps } from '~/types/common-props'
import { WorksTabsContent } from '~/types/data-array'
import { fileToImageLikeData } from '~/utils'
import useMarketplacesAppDevelopmentStaticQuery from '~/views/Services/MarketplacesAppDevelopment/useMarketplacesAppDevelopmentStaticQuery'

const photoDefault = '../../../assets/images/avatars/AvatarDraft.png'
const photoClientTradervs =
  '../../../assets/images/avatars/clients/client-tradervs.png'

const imageProps: CommonImageProps = {
  width: 50,
  height: 50,
}

const MarketplaceAppDevTabContent = (): WorksTabsContent[] => {
  const query = useMarketplacesAppDevelopmentStaticQuery()

  const portfolioMalleni = getImage(fileToImageLikeData(query.portfolioMalleni))
  const portfolioTradeaBoat = getImage(
    fileToImageLikeData(query.portfolioTradeaBoat),
  )
  const portfolioTradervs = getImage(
    fileToImageLikeData(query.portfolioTradervs),
  )

  const tabContent = [
    {
      key: 'Ecommerce solution',
      gradient: 'gradientMalleni',
      image: portfolioMalleni,
      header: 'Ecommerce solution',
      title:
        'This is the first online marketplace in Europe and the UK that enables users to conveniently buy and sell their services and products all in one platform.',
      resultsLeft: 'Developed a complex marketplace with B2C and C2C segments.',
      resultsRight: 'Implemented an enhanced web design.',
      link: 'custom-multi-vendor-marketplace-platform',
      review:
        '“The Codica company is a team of professionals. Together we created a unique solution for the market in Europe and the United Kingdom that allows buying and selling products and providing services in one place.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoDefault}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'CEO',
      clientPosition: 'Ecommerce Marketplace Company, United Kingdom',
    },
    {
      key: 'Boating portal',
      gradient: 'gradientTradeaboat',
      image: portfolioTradeaBoat,
      header: 'Boat-selling marketplace',
      title:
        'Trade A Boat is an Australia-based marketplace app that helps organize buying and selling process of new or used boats and marine equipment.',
      resultsLeft:
        'Created an intuitive, robust, and fast-loading web solution.',
      resultsRight: 'Increased conversion rate with the UX and UI redesign.',
      link: 'online-marketplace-for-boats',
      review:
        '“During the work on the project, Codica’s experts showed great communication skills and a high level of professionalism. They performed the work with high quality, taking into account all our requirements, and delivered the solution within the agreed time frame.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientTradervs}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Celso Prado',
      clientPosition: 'Digital General Manager at the Adventures Group',
    },
    {
      key: 'RVs marketplace',
      gradient: 'gradientTradervs',
      image: portfolioTradervs,
      header: 'Caravan-selling platform',
      title:
        'TradeRVs is a popular Australian marketplace where private merchants and dealers can sell or buy new or used caravans, camper trailers, motorhomes, parts and accessories, and other recreational vehicles.',
      resultsLeft: 'Made platform user- and mobile-friendly.',
      resultsRight: 'Deliver the third-party integrations.',
      link: 'online-marketplace-for-caravans',
      review:
        '“During the work on the project, Codica’s experts showed great communication skills and a high level of professionalism. They performed the work with high quality, taking into account all our requirements, and delivered the solution within the agreed time frame.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientTradervs}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Celso Prado',
      clientPosition: 'Digital General Manager at the Adventures Group',
    },
  ]

  return tabContent
}

export default MarketplaceAppDevTabContent
