import { ExpertiseSectionMock } from '~/types/mock'

const MarketplaceAppDevOnline: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_34',
    title: 'SEO-optimized',
    description:
      'We specialize in developing marketplace apps that adhere to SEO requirements, enabling to increase online visibility.',
  },
  {
    icon: 'spriteIcon_61',
    title: 'User-friendly',
    description:
      'Our primary focus is on delivering visually appealing, intuitive, and user-friendly marketplace apps.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'Security',
    description:
      'We employ robust security measures and utilize built-in security tools to ensure the resilience of your app against potential threats.',
  },
  {
    icon: 'spriteIcon_3',
    title: 'Engaging',
    description:
      'Our dedicated team at Codica develops engaging marketplace apps that captivate and retain audience.',
  },
  {
    icon: 'spriteIcon_17',
    title: 'Speed',
    description:
      'We prioritize delivering the best possible user experience by utilizing cutting-edge technologies that optimize loading speed of the app.',
  },
  {
    icon: 'spriteIcon_33',
    title: 'Cost-effective',
    description:
      'Our team of experts offers a reasonable marketplace app development cost, using an approach tailored to suit the specific needs of your project.',
  },
]

export default MarketplaceAppDevOnline
