import { getImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksTabs from '~/components/items/DiscoverWorksTabs'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/marketplace-app-development/Anchors'
import MarketplaceAppDevBenefits from '~/mock/services/marketplace-app-development/MarketplaceAppDevBenefits'
import MarketplaceAppDevBestPractices from '~/mock/services/marketplace-app-development/MarketplaceAppDevBestPractices'
import MarketplaceAppDevExperience from '~/mock/services/marketplace-app-development/MarketplaceAppDevExperience'
import MarketplaceAppDevFAQ from '~/mock/services/marketplace-app-development/MarketplaceAppDevFAQ'
import MarketplaceAppDevIndustries from '~/mock/services/marketplace-app-development/MarketplaceAppDevIndustries'
import MarketplaceAppDevOnline from '~/mock/services/marketplace-app-development/MarketplaceAppDevOnline'
import MarketplaceAppDevProcess from '~/mock/services/marketplace-app-development/MarketplaceAppDevProcess'
import MarketplaceAppDevServices from '~/mock/services/marketplace-app-development/MarketplaceAppDevServices'
import MarketplaceAppDevTabContent from '~/mock/services/marketplace-app-development/MarketplaceAppDevTabContent'
import MarketplaceAppDevTabList from '~/mock/services/marketplace-app-development/MarketplaceAppDevTabList'
import { fileToImageLikeData } from '~/utils'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesTechStackSectionCrossPlatform from '~/views/Services/components/ServicesTechStackSectionCrossPlatform'
import useMarketplacesAppDevelopmentStaticQuery from '~/views/Services/MarketplacesAppDevelopment/useMarketplacesAppDevelopmentStaticQuery'

import * as containerStyles from './MarketplacesAppDevelopment.module.scss'

const MarketplacesAppDevelopmentServices = () => {
  const query = useMarketplacesAppDevelopmentStaticQuery()
  const marketplaceAppDevTabContent = MarketplaceAppDevTabContent()
  const clientPMK = getImage(fileToImageLikeData(query.clientPMK))

  return (
    <MainLayout scrollTop>
      <section className={containerStyles.marketplaceAppDevPrimeSection}>
        <PrimeSectionServices
          titleTop="Marketplace App"
          title="Development Company"
          description="Codica is an experienced web app development company that creates profitable marketplaces to optimize the connection of vendors and buyers."
          breadcrumbSecondLevel="Services"
          breadcrumbSecondLevelPath="/services/"
          breadcrumbThirdLevel="Online Marketplace Development Services"
          breadcrumbThirdLevelPath="/services/online-marketplace-development/"
          breadcrumbCurrent="Marketplace App Development Company"
          breadcrumbCurrentPath="/services/online-marketplace-development/marketplace-app-development/"
        />
      </section>

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.marketplaceAppDevServicesSection}
      >
        <HeadSection
          position="center"
          title="Marketplace app development services we offer"
          description="Capture the entire market in your niche by utilizing our top-notch software services - we will create a powerful online marketplace app for you that makes a significant impact!"
          descriptionLarge
        />
        <Service3DIconCard dataContent={MarketplaceAppDevServices} />
      </section>

      <section
        id="industries"
        className={containerStyles.marketplaceAppDevIndustriesSection}
      >
        <HeadSection
          position="center"
          title="Industries we cover"
          description="With our extensive experience in developing marketplace apps for specific industries, we have cultivated in-house expertise that we are eager to share with our clients. We offer consultations at every stage of the project development process and possess the knowledge to build apps that adhere to the highest industry standards."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={MarketplaceAppDevIndustries} />
      </section>

      <CallToAction
        title="Do you want to grow your business?"
        subtitle="We will create a top marketplace app for you."
        classNameForGA="servicesCTA_1"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section
        id="benefits"
        className={containerStyles.marketplaceAppDevBenefitsSection}
      >
        <HeadSection
          position="center"
          title="Types of marketplace apps"
          description="Our experts can create diverse types of marketplace apps, each catering to different business models and industry needs. Turn to us for a fully functional app created within the allocated timeframe."
          descriptionLarge
        />
        <ServicesIconSmallCard isLeft dataContent={MarketplaceAppDevBenefits} />
      </section>

      <section
        id="process"
        className={containerStyles.marketplaceAppDevProcessSection}
      >
        <HeadSection
          position="center"
          title="Process of marketplace app development"
          description="Marketplace app development is a comprehensive process that requires close attention to detail. At Codica, we provide our clients with full assistance at every stage of the app development marketplace process, from initial idea analysis to post-launch support."
          descriptionLarge
        />
        <ServicesProcess dataContent={MarketplaceAppDevProcess} />
      </section>

      <section className={containerStyles.marketplaceAppDevTechSection}>
        <HeadSection
          position="center"
          title="Technologies we use"
          description="Throughout our years of experience, we have curated a selection of tools that greatly assist us in constructing top-notch marketplace apps. These tools empower us to deliver marketplace apps that meet our clients' business needs and budgetary requirements."
          descriptionLarge
        />
        <ServicesTechStackSectionCrossPlatform
          isCrossPlatformMobileAppTech
          isAndroidTech
          isIosTech
        />
      </section>

      <CallToAction
        title="Have a groundbreaking mobile app idea?"
        subtitle="It’s time to turn your vision into a reality with our team."
        classNameForGA="servicesCTA_2"
        linkName="Contact us"
        link="/contacts/"
      />

      <section className={containerStyles.marketplaceAppDevOnlineSection}>
        <HeadSection
          position="center"
          title="Online marketplace app development with Codica"
          description="Let us help you create an intuitive marketplace app that drives conversions. These are some of the benefits you will receive with Codica’s marketplace development services."
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={MarketplaceAppDevOnline} />
      </section>

      <section
        id="practices"
        className={containerStyles.marketplaceAppDevPracticesSection}
      >
        <HeadSection
          position="center"
          title="Best practices of marketplace app development"
          description="At Codica, we follow the best marketplace web and marketplace mobile app development practices. They enable us to create robust and successful marketplace apps that deliver value to users, foster trust, and drive growth in the target market."
          descriptionLarge
        />
        <ServicesIconSmallCard
          isBorder
          dataContent={MarketplaceAppDevBestPractices}
        />
      </section>

      <section
        id="cases"
        className={containerStyles.marketplaceAppDevCaseSection}
      >
        <HeadSection position="left" title="Case studies by Codica" />
        <DiscoverWorksTabs
          dataTabList={MarketplaceAppDevTabList}
          dataContent={marketplaceAppDevTabContent}
        />
      </section>

      <section className={containerStyles.marketplaceAppExpertiseSection}>
        <HeadSection
          position="center"
          title="Codica’s experience"
          description="With over eight years of experience, we developed many innovative software products that drive business growth across diverse industries. Our commitment to delivering top-quality services has earned Codica’s marketplace development company a strong reputation and high credibility in the market."
          descriptionLarge
        />
        <ServicesDevExpertiseCompany
          dataContent={MarketplaceAppDevExperience}
        />
      </section>

      <section className={containerStyles.marketplaceAppDevClientQuotes}>
        <HeadSection
          position="center"
          title="Our satisfied clients"
          description="We are committed to delivering exceptional software solutions that go beyond our clients’ expectations and enable them to accomplish their business objectives. Our client base is diverse, yet they all share a common thread - their satisfaction with the services we provide."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="David Watkins"
            location="USA"
            position="Founder of PlanMyKids"
            image={clientPMK}
            quote={[
              {
                text: '“The PlanMyKids journey started when my family struggled to plan our own kid’s activities. As busy parents, we were time-constrained, the information we needed was all over the place, and coordinating with family felt like it required a project management certification.”',
              },
            ]}
            link="kids-activities-service-marketplace"
            videoLink="https://www.youtube.com/embed/dzxMCDLghZQ"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s build a winning marketplace app solution together!"
          widthTitle="750px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our custom marketplace development experts will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={MarketplaceAppDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default MarketplacesAppDevelopmentServices
