import { ExpertiseSectionMock } from '~/types/mock'

const MarketplaceAppDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'App development consulting',
    link: '/services/product-discovery/',
    description:
      'We conduct project discovery sessions to gather project details, understand the niche clients intend to enter, and identify an ideal solution that will enhance demand.',
  },
  {
    icon: 'sprite3DIcon_11',
    title: 'Custom marketplace app development',
    description:
      'Our experienced software development team will create a unique and personalized app tailored to meet your specific needs and requirements.',
  },
  {
    icon: 'sprite3DIcon_4',
    title: 'MVP development',
    link: '/services/mvp-development/',
    description:
      'Our minimum viable product development services are focused on creating an initial version of your marketplace to validate your business idea quickly.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'UI/UX design',
    link: '/services/ui-ux-design/',
    description:
      'Our designers focus on creating visually appealing and user-friendly interfaces for your marketplace app to drive user engagement.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'Quality assurance',
    link: '/services/quality-assurance/',
    description:
      'We constantly check your marketplace app’s functionality, performance, and reliability to find bugs in the early stages.',
  },
  {
    icon: 'sprite3DIcon_22',
    title: 'Third-party integrations',
    description:
      'With third-party integrations, we can expand your app’s capabilities, streamline processes, and provide additional value to your users.',
  },
]

export default MarketplaceAppDevServices
